import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Map } from 'immutable';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import TimePicker from 'rc-time-picker';
import styles from './RowCashMovementItem.less';
import 'rc-time-picker/assets/index.css';

class RowCashMovementItem extends PureComponent {
	constructor() {
		super();
		this.onChangeTimeHandler = this.onChangeTimeHandler.bind(this);
		this.onChangeHandler = this.onChangeHandler.bind(this);
		this.onRemoveHandler = this.onRemoveHandler.bind(this);
		this.onToggleModalSalesmanHandler = this.onToggleModalSalesmanHandler.bind(this);
	}

	onChangeTimeHandler(value) {
		const { index, onChange } = this.props;
		onChange(index, 'time', value);
	}

	onChangeHandler(e) {
		const { index, onChange } = this.props;
		onChange(
			index,
			e.target.id,
			e.target.value
		);
	}

	onRemoveHandler() {
		const { index, onRemove } = this.props;
		onRemove(index);
	}

	onToggleModalSalesmanHandler(e) {
		e && e.target.blur();
		const { index } = this.props;
		this.props.onToggleModalSalesman(index);
	}

	render() {
		const { isReadOnly, cashMovementItem } = this.props;
		if (isReadOnly) {
			return (
				<tr>
					<td>{ cashMovementItem.get('time').format('HH:mm:ss') }</td>
					<td>{ cashMovementItem.get('salesman_code') }</td>
					<td>{ cashMovementItem.get('description') }</td>
					<td>{ cashMovementItem.get('debit_amount') }</td>
					<td>{ cashMovementItem.get('credit_amount') }</td>
					<td></td>
				</tr>
			);
		}
		return (
			<tr>
				<td>
					<TimePicker
						format="HH:mm:ss"
						disabled={ isReadOnly }
						allowEmpty={ false }
						className={ styles.timePicker }
						value={ cashMovementItem.get('time') || '' }
						onChange={ this.onChangeTimeHandler }
					/>
				</td>
				<td>
					<input
						type="text"
						disabled={ isReadOnly }
						className="uk-input uk-text-center"
						value={ cashMovementItem.get('salesman_code') || '' }
						onFocus={ this.onToggleModalSalesmanHandler }
						readOnly
					/>
				</td>
				<td>
					<input
						id="description"
						type="text"
						disabled={ isReadOnly }
						className="uk-input uk-text-center"
						value={ cashMovementItem.get('description') || '' }
						onChange={ this.onChangeHandler }
					/>
				</td>
				<td>
					<input
						id="debit_amount"
						type="text"
						disabled={ isReadOnly }
						className="uk-input uk-text-center"
						value={ cashMovementItem.get('debit_amount') || '' }
						onChange={ this.onChangeHandler }
					/>
				</td>
				<td>
					<input
						id="credit_amount"
						type="text"
						disabled={ isReadOnly }
						className="uk-input uk-text-center"
						value={ cashMovementItem.get('credit_amount') || '' }
						onChange={ this.onChangeHandler }
					/>
				</td>
				<td>
					<div className={ classnames('uk-text-danger', styles.remove) } onClick={ this.onRemoveHandler }>
						<FontAwesomeIcon icon={ faTrashAlt } />
					</div>
				</td>
			</tr>
		);
	}
}

RowCashMovementItem.propTypes = {
	index: PropTypes.number.isRequired,
	isReadOnly: PropTypes.bool.isRequired,
	cashMovementItem: PropTypes.instanceOf(Map),
	onChange: PropTypes.func.isRequired,
	onRemove: PropTypes.func.isRequired,
	onToggleModalSalesman: PropTypes.func.isRequired,
};

export default RowCashMovementItem;