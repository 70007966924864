import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import PageCashMovement from 'Pages/cash-movement';
import PageCashMovementEdit from 'Pages/cash-movement-edit';

const Report = ({ match }) => (
	<div className="dashboard-wrapper">
		<Switch>
			<Route exact path={`${match.url}`} component={ PageCashMovement } />
			<Route exact path={`${match.url}/edit/:date`} component={ PageCashMovementEdit } />
			<Redirect to="/error" />
		</Switch>
	</div>
);
export default Report;