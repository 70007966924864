import React, { PureComponent } from 'react';
import classnames from 'classnames';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { extractDateFromDate } from 'Helpers/helpers';
import { getCashMovementRecords } from 'Redux/actions';
import Top from 'Components/top';
import LoadingOverlay from 'Components/loading-overlay';
import Pagination from 'Components/pagination';
import loc from 'Components/languages';
import CashMovementListItem from './components/CashMovementListItem.jsx';
import styles from './PageCashMovement.less';

class PageCashMovement extends PureComponent {
	constructor() {
		super();
		this.state = {
			page: 1,
		};
		this.fetchData = this.fetchData.bind(this);
		this.isLoading = this.isLoading.bind(this);
		this.onChangePageHandler = this.onChangePageHandler.bind(this);
		this.onSelectHandler = this.onSelectHandler.bind(this);
	}

	componentDidMount() {
		this.fetchData();
	}

	fetchData() {
		const { page } = this.state;
		const param = {
			payload: {
				page,
				size: 10,
				sort_column: 'cash_movement_date',
				sort_direction: 'desc',
			}
		};
		this.props.getCashMovementRecords(param);
	}

	isLoading() {
		const { cashMovementRecordsInfo } = this.props;
		return cashMovementRecordsInfo.isFetching;
	}

	onChangePageHandler(data) {
		this.setState({
			page: data.selected + 1,
		}, this.fetchData);
	}

	onSelectHandler(record) {
		this.props.history.push(`/app/cash-movement/edit/${extractDateFromDate(record.cash_movement_date)}`);
	}

	render() {
		const { cashMovementRecordsInfo } = this.props;
		const isLoading = this.isLoading();
		return (
			<LoadingOverlay active={ isLoading }>
				<div className="uk-flex uk-flex-column">
					<Top name="cashMovement" />

					<div
						className={ classnames('uk-height-large', 'uk-overflow-auto', styles.list) }
						data-uk-height-viewport="offset-top: true; offset-bottom: 87px"
					>
						<h3 className="uk-text-primary uk-text-bold uk-text-uppercase uk-padding-small uk-margin-remove-bottom uk-padding-remove-top">
							{ loc.selectRecord }
						</h3>

						<ul className="uk-list uk-list-divider uk-margin-remove-top">
							{
								cashMovementRecordsInfo.data && cashMovementRecordsInfo.data.data && cashMovementRecordsInfo.data.data.map((record, index) => (
									<CashMovementListItem
										key={ `page-cash-movement-list-item-${index}` }
										index={ index }
										record={ record }
										onSelect={ this.onSelectHandler }
									/>
								))
							}
						</ul>
					</div>

					<Pagination
						data={ cashMovementRecordsInfo.data }
						onPageChange={ this.onChangePageHandler }
					/>
				</div>
			</LoadingOverlay>
		);
	}
}

export default withRouter(connect(
	(state) => ({
		cashMovementRecordsInfo: state.cashMovementRecordsInfo,
	}),
	(dispatch) => ({
		getCashMovementRecords: para => dispatch(getCashMovementRecords(para)),
	})
)(PageCashMovement));