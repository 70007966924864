import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import loc from 'Components/languages';
import { convertFloatToPrice, extractDateFromDate } from 'Helpers/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import styles from './CashMovementListItem.less';

class CashMovementListItem extends PureComponent {
	constructor() {
		super();
		this.onClickHandler = this.onClickHandler.bind(this);
	}

	onClickHandler() {
		const { record, onSelect } = this.props;
		onSelect(record);
	}

	render() {
		const { record } = this.props;
		return (
			<li
				onClick={ this.onClickHandler }
				className={ classnames(styles.list) }
			>
				<div className="uk-card uk-card-body uk-card-small uk-position-relative">
					<div className="uk-flex uk-flex-between uk-text-small uk-text-muted uk-text-capitalize">
						<div className="uk-width-1-3">
							<div className="uk-text-primary uk-h5 uk-margin-remove-bottom">{ loc.date }: { extractDateFromDate(record.cash_movement_date) }</div>
							<div>{ loc.salesman }: { record.salesman_code }</div>
						</div>
						<div className="uk-width-1-3">
							<div>&nbsp;</div>
							<div>{ loc.currency }: { record.currency }</div>
						</div>
						<div className="uk-width-1-3 uk-position-relative">
							<div>&nbsp;</div>
							<div><strong>{ loc.opening }: { convertFloatToPrice(record.opening) }</strong></div>
							

							<div className="uk-position-center-right">
								<FontAwesomeIcon icon={ faChevronRight } className="fa-lg" />
							</div>
						</div>
					</div>
				</div>
			</li>
		);
	}
}

CashMovementListItem.propTypes = {
	index: PropTypes.number.isRequired,
	record: PropTypes.object.isRequired,
	onSelect: PropTypes.func.isRequired,
};

export default CashMovementListItem;